/*eslint-disable */
import { memo, useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import parse from 'html-react-parser';

import { useAppDispatch } from '../../app/hooks';
import RecommendationOrganism from '../../components/organisms/charts/recommendation';
import { GOAL_TYPE, WITHDRAWAL_FREQUENCY, TENANT_ID_MAP, GOAL_PRIORITY_PROBABILITY_OF_SUCCESS } from '../../constants';
import { updateFormWithSuggestions } from '../../features/client-summary';

const isHighPoint =
    TENANT_ID_MAP[document?.location?.host?.split('.')?.reverse()?.[2] || 'advisorportaldev'] === 'highpoint';

const RecommendationContainer = ({ showSkeletonLoader = false, showLoader, response, request, formField }: any) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const currency = isHighPoint ? 'MXN$' : '$';

    const theme: any = useTheme();
    const [goalProb, setGoalProb] = useState(0);
    const [remainProb, setRemainProb] = useState(0);
    const [targetAmount, setTargetAmount] = useState(null);
    const [isIncomeFlow, setIsIncomeFlow] = useState(true);
    const [isRetirementFlow, setIsRetirementFlow] = useState(false);
    const [isSaveForFutureEventFlow, setIsSaveForFutureEventFlow] = useState(false);
    const [isModerateProb, setIsModerateProb] = useState(false);
    const [isBadProb, setIsBadProb] = useState(false);
    const [suggestionList, setSuggestionsList] = useState([] as Array<{ label: any; value: any }>);
    const [goalType, setGoalType] = useState<string>('');

    const onRecommendationClick = (suggestionsToUpdate: any) => {
        dispatch(updateFormWithSuggestions(suggestionsToUpdate));
    };

    function getSuggestionsList(analysisReport: any, formField: any): Array<{ label: any; value: any }> {
        let suggestionArr: Array<{
            label: any;
            value: any;
            fieldName?: any;
            textAfterValue?: any;
        }> = [];
        const infusion = Math.floor(+analysisReport.oneTimeTopUp);

        // We populate suggestionArr only if oneTimeTopUp is > 0
        if (infusion > 0) {
            // Give first suggestion as Increase the initial funding amount by $amount
            suggestionArr = [
                {
                    label: `${t('TEXT_RECOMMENDATION_INCREASE')} ${t('TEXT_RECOMMENDATION_INITIAL_INVESTMENT_BY')}`,
                    value: `${infusion}`,
                    fieldName: 'initialInvestment',
                    textAfterValue: t('TEXT_RECOMMENDATION_PROBABILITY_TEXT', {
                        goalProb: `<span style="color: ${
                            theme.palette.recommendation.amount
                        }; border-bottom: 1px solid ${theme.palette.recommendation.amountBorderBottom}">${
                            GOAL_PRIORITY_PROBABILITY_OF_SUCCESS[formField?.goalPriority]
                        }</span>`
                    })
                }
            ];

            // Check if goalType is retirement or future event
            if (formField?.goalType === GOAL_TYPE.RETIREMENT || formField?.goalType === GOAL_TYPE.FUTURE_EVENT) {
                // If runpipe return value > 0 for both yearlyTopUpAccumulation and monthlyTopUpAccumulation
                if (+analysisReport.yearlyTopUpAccumulation > 0 || +analysisReport.monthlyTopUpAccumulation > 0) {
                    // Check if withdrawalFrequency or scheduledFrequency selected is Anually
                    // If yes suggest, Increase the annual contributions by $amount
                    if (
                        formField?.withdrawalFrequency === WITHDRAWAL_FREQUENCY.YEARLY ||
                        formField?.scheduledFrequency === WITHDRAWAL_FREQUENCY.YEARLY
                    ) {
                        suggestionArr.push({
                            label: `${t('TEXT_RECOMMENDATION_INCREASE')} ${t(
                                'TEXT_RECOMMENDATION_YEARLY_CONTRIBUTION_BY'
                            )}`,
                            value: analysisReport.yearlyTopUpAccumulation,
                            fieldName:
                                formField?.goalType === GOAL_TYPE.RETIREMENT
                                    ? 'scheduledContributionAmount'
                                    : 'scheduledContribution',
                            textAfterValue: t('TEXT_RECOMMENDATION_PROBABILITY_TEXT', {
                                goalProb: `<span style="color: ${
                                    theme.palette.recommendation.amount
                                }; border-bottom: 1px solid ${theme.palette.recommendation.amountBorderBottom}">${
                                    GOAL_PRIORITY_PROBABILITY_OF_SUCCESS[formField?.goalPriority]
                                }</span>`
                            })
                        });
                    }
                    // Check if withdrawalFrequency or scheduledFrequency selected is Monthly
                    // If yes suggest, Increase the monthly contributions by $amount
                    if (
                        formField?.withdrawalFrequency === WITHDRAWAL_FREQUENCY.MONTHLY ||
                        formField?.scheduledFrequency === WITHDRAWAL_FREQUENCY.MONTHLY
                    ) {
                        suggestionArr.push({
                            label: `${t('TEXT_RECOMMENDATION_INCREASE')} ${t(
                                'TEXT_RECOMMENDATION_MONTHLY_CONTRIBUTION_BY'
                            )}`,
                            value: analysisReport.monthlyTopUpAccumulation,
                            fieldName:
                                formField?.goalType === GOAL_TYPE.RETIREMENT
                                    ? 'scheduledContributionAmount'
                                    : 'scheduledContribution',
                            textAfterValue: t('TEXT_RECOMMENDATION_PROBABILITY_TEXT', {
                                goalProb: `<span style="color: ${
                                    theme.palette.recommendation.amount
                                }; border-bottom: 1px solid ${theme.palette.recommendation.amountBorderBottom}">${
                                    GOAL_PRIORITY_PROBABILITY_OF_SUCCESS[formField?.goalPriority]
                                }</span>`
                            })
                        });
                    }
                }
            }

            // Check if goalType is generate income or retirement
            if (formField?.goalType === GOAL_TYPE.GENERATE_INCOME || formField?.goalType === GOAL_TYPE.RETIREMENT) {
                // If runpipe return value > 0 for both yearlyTopUpDecumulation and monthlyTopUpDecumulation
                if (+analysisReport.yearlyTopUpDecumulation > 0 || +analysisReport.monthlyTopUpDecumulation > 0) {
                    // Check if withdrawalFrequency or scheduledFrequency selected is Anually
                    // If yes suggest, Reduce the annual withdrawals by $amount
                    if (formField?.withdrawalFrequency === WITHDRAWAL_FREQUENCY.YEARLY) {
                        suggestionArr.push({
                            label: `${t('TEXT_RECOMMENDATION_REDUCE')} ${t(
                                'TEXT_RECOMMENDATION_YEARLY_WITHDRAWALS_BY'
                            )}`,
                            value: `${+analysisReport.yearlyTopUpDecumulation}`,
                            fieldName:
                                formField?.goalType === GOAL_TYPE.GENERATE_INCOME
                                    ? 'scheduledWithdrawalAmount'
                                    : 'withdrawalAmount',
                            textAfterValue: t('TEXT_RECOMMENDATION_PROBABILITY_TEXT', {
                                goalProb: `<span style="color: ${
                                    theme.palette.recommendation.amount
                                }; border-bottom: 1px solid ${theme.palette.recommendation.amountBorderBottom}">${
                                    GOAL_PRIORITY_PROBABILITY_OF_SUCCESS[formField?.goalPriority]
                                }</span>`
                            })
                        });
                    }
                    // Check if withdrawalFrequency or scheduledFrequency selected is Monthly
                    // If yes suggest, Reduce the monthly withdrawals by $amount
                    if (formField?.withdrawalFrequency === WITHDRAWAL_FREQUENCY.MONTHLY) {
                        suggestionArr.push({
                            label: `${t('TEXT_RECOMMENDATION_REDUCE')} ${t(
                                'TEXT_RECOMMENDATION_MONTHLY_WITHDRAWALS_BY'
                            )}`,
                            value: `${+analysisReport.monthlyTopUpDecumulation}`,
                            fieldName:
                                formField?.goalType === GOAL_TYPE.GENERATE_INCOME
                                    ? 'scheduledWithdrawalAmount'
                                    : 'withdrawalAmount',
                            textAfterValue: t('TEXT_RECOMMENDATION_PROBABILITY_TEXT', {
                                goalProb: `<span style="color: ${
                                    theme.palette.recommendation.amount
                                }; border-bottom: 1px solid ${theme.palette.recommendation.amountBorderBottom}">${
                                    GOAL_PRIORITY_PROBABILITY_OF_SUCCESS[formField?.goalPriority]
                                }</span>`
                            })
                        });
                    }
                }
            }

            if (analysisReport.recommendedTenure) {
                const recommendedSuggestions = analysisReport.recommendedTenure.split('; ');

                recommendedSuggestions.forEach((sugg: string) => {
                    const values = sugg.match(/\d+/g);

                    if (formField?.goalType === GOAL_TYPE.RETIREMENT) {
                        suggestionArr.push({
                            label: parse(
                                `${t('TEXT_RECOMMENDATION_RETIEMENT_GOAL_TENURE', {
                                    monthAndYear: `<span id="clickable-span" style="color: ${
                                        theme.palette.recommendation.amount
                                    }; border-bottom: 1px solid ${
                                        theme.palette.recommendation.amountBorderBottom
                                    }; cursor: pointer;">${
                                        String(formField.retirementMonth).length === 1
                                            ? `0${formField.retirementMonth}`
                                            : formField.retirementMonth
                                    }/${formField.retirementYear + Number(values?.[0] || 0)}</span>`,
                                    probability: `<span style="color: ${
                                        theme.palette.recommendation.amount
                                    }; border-bottom: 1px solid ${
                                        theme.palette.recommendation.amountBorderBottom
                                    }">${Number(values?.[1] || 0)}%</span>`
                                })}`,
                                {
                                    replace: ({ attribs }: any) => {
                                        if (attribs?.id === 'clickable-span') {
                                            return (
                                                <span
                                                    id="clickable-span"
                                                    style={{
                                                        color: theme.palette.recommendation.amount,
                                                        borderBottom: `1px solid ${theme.palette.recommendation.amountBorderBottom}`,
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={() =>
                                                        onRecommendationClick({
                                                            retirementMonth: formField.retirementMonth,
                                                            retirementYear:
                                                                formField.retirementYear + Number(values?.[0] || 0)
                                                        })
                                                    }
                                                >
                                                    {`${
                                                        String(formField.retirementMonth).length === 1
                                                            ? `0${formField.retirementMonth}`
                                                            : formField.retirementMonth
                                                    }/${formField.retirementYear + Number(values?.[0] || 0)}`}
                                                </span>
                                            );
                                        }
                                    }
                                }
                            ),
                            value: ''
                        });
                    }

                    if (formField?.goalType === GOAL_TYPE.GENERATE_INCOME) {
                        suggestionArr.push({
                            label: parse(
                                `${t('TEXT_RECOMMENDATION_GENERATE_INCOME_GOAL_TENURE', {
                                    monthAndYear: `<span id="clickable-span" style="color: ${
                                        theme.palette.recommendation.amount
                                    }; border-bottom: 1px solid ${
                                        theme.palette.recommendation.amountBorderBottom
                                    }; cursor: pointer;">${values?.[0] || 0} ${t('TEXT_RECOMMENDATION_YEARS')}</span>`,

                                    probability: `<span style="color: ${
                                        theme.palette.recommendation.amount
                                    }; border-bottom: 1px solid ${
                                        theme.palette.recommendation.amountBorderBottom
                                    }">${Number(values?.[1] || 0)}%</span>`
                                })}`,
                                {
                                    replace: ({ attribs }: any) => {
                                        if (attribs?.id === 'clickable-span') {
                                            return (
                                                <span
                                                    id="clickable-span"
                                                    style={{
                                                        color: theme.palette.recommendation.amount,
                                                        borderBottom: `1px solid ${theme.palette.recommendation.amountBorderBottom}`,
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={() =>
                                                        onRecommendationClick({
                                                            withdrawalPeriod:
                                                                formField.withdrawalPeriod - Number(values?.[0] || 0)
                                                        })
                                                    }
                                                >
                                                    {`${values?.[0] || 0} ${t('TEXT_RECOMMENDATION_YEARS')}`}
                                                </span>
                                            );
                                        }
                                    }
                                }
                            ),
                            value: ''
                        });
                    }

                    if (formField?.goalType === GOAL_TYPE.FUTURE_EVENT) {
                        suggestionArr.push({
                            label: parse(
                                `${t('TEXT_RECOMMENDATION_FUTURE_EVENT_GOAL_TENURE', {
                                    monthAndYear: `<span id="clickable-span" style="color: ${
                                        theme.palette.recommendation.amount
                                    }; border-bottom: 1px solid ${
                                        theme.palette.recommendation.amountBorderBottom
                                    }; cursor: pointer;">${
                                        String(formField.investorPlanMonth).length === 1
                                            ? `0${formField.investorPlanMonth}`
                                            : formField.investorPlanMonth
                                    }/${formField.investorPlanYear + Number(values?.[0] || 0)}</span>`,
                                    probability: `<span style="color: ${
                                        theme.palette.recommendation.amount
                                    }; border-bottom: 1px solid ${
                                        theme.palette.recommendation.amountBorderBottom
                                    }">${Number(values?.[1] || 0)}%</span>`
                                })}`,
                                {
                                    replace: ({ attribs }: any) => {
                                        if (attribs?.id === 'clickable-span') {
                                            return (
                                                <span
                                                    id="clickable-span"
                                                    style={{
                                                        color: theme.palette.recommendation.amount,
                                                        borderBottom: `1px solid ${theme.palette.recommendation.amountBorderBottom}`,
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={() =>
                                                        onRecommendationClick({
                                                            investorPlanMonth: formField.investorPlanMonth,
                                                            investorPlanYear:
                                                                formField.investorPlanYear + Number(values?.[0] || 0)
                                                        })
                                                    }
                                                >
                                                    {`${
                                                        String(formField.investorPlanMonth).length === 1
                                                            ? `0${formField.investorPlanMonth}`
                                                            : formField.investorPlanMonth
                                                    }/${formField.investorPlanYear + Number(values?.[0] || 0)}`}
                                                </span>
                                            );
                                        }
                                    }
                                }
                            ),
                            value: ''
                        });
                    }
                });
            }
        }

        return suggestionArr;
    }

    function setProbabilityType() {
        let isModerateProb = false;
        let isBadProb = false;
        const analysisReport = response.analysisReport;
        const meetGoalPriority = analysisReport.meetGoalPriority;
        const isGoalRealistic = analysisReport.isGoalRealistic;

        // If goal meets the priority and the goal is realistic
        if (meetGoalPriority && isGoalRealistic) {
            isModerateProb = false;
            isBadProb = false;
        }
        // If Goal does not meet priority but goal is realistic
        else if (!meetGoalPriority && isGoalRealistic) {
            isModerateProb = true;
            isBadProb = false;
            setSuggestionsList(getSuggestionsList(response.analysisReport, formField));
        }
        // If Goal does not meet priority and goal is not realistic
        else if (!meetGoalPriority && !isGoalRealistic) {
            isBadProb = true;
            isModerateProb = false;
        }

        setIsModerateProb(isModerateProb);
        setIsBadProb(isBadProb);
    }

    useEffect(() => {
        if (!isEmpty(response) && !isEmpty(formField)) {
            const analysisReport = response?.analysisReport;

            // Setting Goal Probability
            setGoalProb(Math.round(analysisReport?.currentGoalProbability * 100));
            // Setting loss threshold porbability
            setRemainProb(Math.round(analysisReport?.currentLossThresholdProbability * 100));

            // Setting which flow we are looking at
            setIsIncomeFlow(formField?.goalType === GOAL_TYPE.GENERATE_INCOME);
            setIsRetirementFlow(formField?.goalType === GOAL_TYPE.RETIREMENT);
            setIsSaveForFutureEventFlow(formField?.goalType === GOAL_TYPE.FUTURE_EVENT);

            // Determine the values of isModerateProb and isBadProb
            // Also set suggestionList If Goal does not meet priority but goal is realistic
            setProbabilityType();

            // Setting goalType and targetAmount
            setGoalType(formField?.goalType);
            setTargetAmount(formField?.goalAmount);
        }
    }, [response, formField]);

    return (
        <RecommendationOrganism
            currency={currency}
            theme={theme}
            translation={t}
            goalProb={goalProb}
            remainProb={remainProb}
            targetAmount={targetAmount}
            isIncomeFlow={isIncomeFlow}
            isRetirementFlow={isRetirementFlow}
            isSaveForFutureEventFlow={isSaveForFutureEventFlow}
            suggestionList={suggestionList}
            isModerateProb={isModerateProb}
            isBadProb={isBadProb}
            request={request}
            response={response}
            showLoader={showLoader}
            formField={formField}
            showSkeletonLoader={showSkeletonLoader}
            goalType={goalType}
        />
    );
};

export default memo(RecommendationContainer);
