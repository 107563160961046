import { useState, KeyboardEvent } from 'react';
import AppBar from '@mui/material/AppBar';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { useOktaAuth } from '@okta/okta-react';
import parse from 'html-react-parser';

import { useAppSelector } from '../../../app/hooks';
import { updateGlobalLoaderState } from '../../../features/global/globalSlice';
import { ReactComponent as Logo } from './assests/logo.svg';
import { getInitials, getAdvisorName } from '../../../utils';
import Menu from './menu';

const ContentContainer = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '24px 0',
    margin: '0 35px'
}));

const BrandName = styled(Typography)<TypographyProps>(({ theme }) => ({
    color: theme.palette.common.white,
    '@media (max-width: 991px)': {
        display: 'none'
    }
}));

const HeaderRightSectionContainer = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    minWidth: '200px',
    '@media (max-width: 480px)': {
        minWidth: '0'
    }
}));

const UserNameTypography = styled(Typography)<TypographyProps>(({ theme }) => ({
    color: theme.palette.common.white,
    cursor: 'pointer',
    '@media (max-width: 481px)': {
        display: 'none'
    }
}));

const UserNameLogo = styled('div')(() => ({
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    border: '1px solid',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '1rem',
    fontWeight: '600',
    marginRight: '20px',
    '@media (min-width: 480px)': {
        display: 'none'
    }
}));

const formatUsername = (username?: string) => {
    if (!username) return '';

    const [firstName, lastName] = username.split(',');

    return `${firstName}${lastName?.trim() ? `, ${lastName?.trim()}` : ''}`;
};

const HeaderComponent = ({
    eulaVariant = false,
    showHeaderWithoutOptions
}: {
    eulaVariant?: boolean;
    showHeaderWithoutOptions?: boolean;
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { authState, oktaAuth } = useOktaAuth();
    const isAuthenticated = authState?.isAuthenticated;
    const isAuthLoading = useAppSelector((state) => state.auth.loading);
    const { lastLoggedInDateTime, loggedInUsername, userEmail } = useAppSelector((state) => state.auth.user);
    const { logo, subdomain } = useAppSelector((state) => state.global?.globalConfig || {});
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        if (event?.currentTarget) {
            setAnchorEl(event.currentTarget);
        }
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = async () => {
        setAnchorEl(null);
        updateGlobalLoaderState(true);

        oktaAuth.tokenManager.clear();
        navigate('/');
    };

    const handleKeyPress = (e: KeyboardEvent<HTMLInputElement> | any | null) => {
        setAnchorEl(e.currentTarget);
    };

    if (!(isAuthenticated && !isAuthLoading) && !showHeaderWithoutOptions) {
        return null;
    }

    return (
        <AppBar position="static" elevation={0} sx={{ backgroundColor: 'layout.primary' }}>
            <div>
                <ContentContainer>
                    <>
                        {logo ? (
                            <img
                                src={logo}
                                alt="Franklin Templeton"
                                data-testid="brand-logo"
                                width={subdomain === 'highpoint' ? '140' : '200'}
                                height="auto"
                            />
                        ) : (
                            <Logo title="login" data-testid="brand-logo" />
                        )}
                    </>
                    <BrandName variant="h2" noWrap tabIndex={0} data-testid="brand-name">
                        {t('TEXT_BRAND')}
                    </BrandName>
                    <HeaderRightSectionContainer>
                        {!eulaVariant && !showHeaderWithoutOptions ? (
                            <>
                                <UserNameTypography
                                    variant="body1"
                                    noWrap
                                    aria-controls={open ? 'menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                    tabIndex={0}
                                    onPointerEnter={handleClick}
                                    onClick={handleClick}
                                    onKeyPress={(e) => handleKeyPress(e)}
                                    data-testid="logged-in-user-menu-link"
                                >
                                    {parse(
                                        t('TEXT_WELCOME', {
                                            USERNAME: formatUsername(loggedInUsername)
                                        })
                                    )}
                                </UserNameTypography>
                                {loggedInUsername && (
                                    <UserNameLogo
                                        aria-controls={open ? 'menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={open ? 'true' : undefined}
                                        tabIndex={0}
                                        onClick={handleClick}
                                        onKeyPress={(e) => handleKeyPress(e)}
                                        data-testid="user-name-logo"
                                    >
                                        {getInitials(getAdvisorName(formatUsername(loggedInUsername)))}
                                    </UserNameLogo>
                                )}
                            </>
                        ) : null}
                    </HeaderRightSectionContainer>
                    <Menu
                        open={open}
                        anchorEl={anchorEl}
                        handleClose={handleClose}
                        lastLoggedInDateTime={lastLoggedInDateTime}
                        loggedInUsername={formatUsername(loggedInUsername)}
                        userEmail={userEmail}
                        handleLogout={handleLogout}
                    />
                </ContentContainer>
            </div>
        </AppBar>
    );
};
export default HeaderComponent;
